.select_type .MuiInputLabel-outlined {
    transform: translate(14px, 11px) scale(1);
}
.select_type .MuiOutlinedInput-input {
    padding: 0 14px !important;
}
.select_type .MuiSelect-selectMenu {
    min-height: 38px;
}
.report-container .MuiSelect-selectMenu{
    display: flex;
    align-items: center;
}
.report-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
}