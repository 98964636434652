.reporting-section .reporting-menu-header{
    width: 100%;
    font-size: 20px;
    text-align: center;
}
.reporting-section .reporting-table-header{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
}
/*.reporting-section .ReportingTable{*/
/*    height: 600px;*/
/*}*/
.reporting-section .ReportingTable .table td:nth-child(5){
    word-break: break-all;
    padding: 4px;
}
.reporting-table .ReportingTable th{
    font-size: 13px !important;
}
.reporting-section .menu-item{
    width: 75%;
    margin-top: 5px;
    text-align: center;
}

.reporting-section .date-picker-container{
    display: flex;
    justify-content: center;
}
.reporting-section .date-picker-container input{
    padding: 5px;
    border-width: 1px;
}
.reporting-section .date-picker-container .react-datepicker-wrapper{
    width: auto;
    margin: 0;
}
.reporting-section .reporting-table .form-control{
    width: 100px;
}
.reporting-section .reporting-table .countController .form-label{
    display: flex;
    align-items: center;
    margin-right: 5px;
    margin-bottom: 0;
}
.reporting-section .reporting-table .countController{
    font-size: 18px;
    font-weight: 500;
    margin: 0;
}
.date-picker-icon{
    position: relative;
    margin-right: 20px;
}
.date-picker-icon img{
    top: 4px;
    position: absolute;
    right: 7px;
    pointer-events: none;
}
