.user-information-section .react-bootstrap-table th:last-child {
    width: 10%;
    font-family: 'Open Sans', sans-serif;
}
.user-information-section .react-bootstrap-table td:last-child {
    text-align: center;
}

.user-information-section .form-control {
    height: calc(1.2em + .75rem + 2px);
    padding: .375rem 5px;
}

.user-information-section textarea.form-control {
    height: auto !important;
}

.user-information-section .form-group {
    text-align: left;
    margin-bottom: 10px;
    align-items: center;
}

.user-information-section .form-group .form-label {
    font-size: 14px;
    margin-bottom: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
}

.user-information-section .inner-user-info-section {
    margin: 0 10px;
}

.user-information-section .inner-user-info-section .row {
    margin-right: 0;
    margin-left: 0;
}

.user-information-section .city-pincode-section .col-lg-4 {
    padding: 0 !important;
}

.user-information-section .city-section {
    width: 75%;
    margin-left: 5px;
}

/* tab */

.user-list-table-left-side {
    overflow: auto;
}

.user-information-section .user-list-table-left-side .react-bootstrap-table {
    overflow: auto;
}
/*#style-2::-webkit-scrollbar-track {*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);*/
/*    border-radius: 10px;*/
/*    background-color: #F5F5F5;*/
/*}*/

/*#style-2::-webkit-scrollbar {*/
/*    background-color: #F5F5F5;*/
/*    height: 10px;*/
/*}*/

/*#style-2::-webkit-scrollbar-thumb {*/
/*    border-radius: 10px;*/
/*    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);*/
/*    background-color: #555;*/
/*}*/

.contact-tab .react-bootstrap-table th:nth-child(5n+4) {
    width: 9%;
    text-align: center !important;
    padding-left: 0 !important;
}

.contact-tab .react-bootstrap-table td:nth-child(5n+4) {
    text-align: center !important;
    padding-left: 0 !important;
}

.user-information-section .react-tabs {
    text-align: left;
}

.user-information-section .react-tabs__tab {
    position: inherit !important;
}

.user-information-section .react-tabs .react-tabs__tab-list {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    font-family: 'Open Sans', sans-serif;
}

.user-information-section .react-tabs .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 14px 0;
    cursor: pointer;
    text-align: center;
    width: 16.666%;
    color: white;
    font-family: 'Open Sans', sans-serif;
}

.user-information-section .react-tabs__tab-list {
    background: indigo;
    text-align: center;
}

.user-information-section .react-tabs, .user-information-section .react-tabs .react-tabs__tab-list {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
}

.user-information-section .react-tabs__tab:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
}

.user-information-section .react-tabs .react-tabs__tab--selected {
    border-color: transparent;
    background: white;
    border-radius: 0 !important;
    color: black !important;
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.user-information-section .table td, .user-information-section .table th {
    padding: 10px 1px;
    font-size: 14px;
    text-align: left;
}

.user-information-section .col, .user-information-section .col-1, .user-information-section .col-10, .user-information-section .col-11, .user-information-section .col-12, .user-information-section .col-2, .user-information-section .col-3, .user-information-section .col-4, .user-information-section .col-5, .user-information-section .col-6, .user-information-section .col-7, .user-information-section .col-8, .user-information-section .col-9, .user-information-section .col-auto, .user-information-section .col-lg, .user-information-section .col-lg-1, .user-information-section .col-lg-10, .user-information-section .col-lg-11, .user-information-section .col-lg-12, .user-information-section .col-lg-2, .user-information-section .col-lg-3, .user-information-section .col-lg-4, .user-information-section .col-lg-5, .user-information-section .col-lg-6, .user-information-section .col-lg-7, .user-information-section .col-lg-8, .user-information-section .col-lg-9,
.user-information-section .col-lg-auto, .user-information-section .col-md, .user-information-section .col-md-1,
.user-information-section .col-md-10, .user-information-section .col-md-11,
.user-information-section .col-md-12, .user-information-section .col-md-2,
.user-information-section .col-md-3, .user-information-section .col-md-4,
.user-information-section .col-md-5, .user-information-section .col-md-6, .user-information-section .col-md-7, .user-information-section .col-md-8, .user-information-section .col-md-9, .col-md-auto, .user-information-section .col-sm, .user-information-section .col-sm-1, .col-sm-10,
.user-information-section .col-sm-11, .col-sm-12, .user-information-section .col-sm-2, .user-information-section .col-sm-3, .user-information-section .col-sm-4, .user-information-section .col-sm-5, .user-information-section .col-sm-6,
.user-information-section .col-sm-7, .user-information-section .col-sm-8, .user-information-section .col-sm-9, .user-information-section .col-sm-auto, .user-information-section .col-xl, .user-information-section .col-xl-1, .user-information-section .col-xl-10,
.user-information-section .col-xl-11, .user-information-section .col-xl-12, .user-information-section .col-xl-2, .user-information-section .col-xl-3, .user-information-section .col-xl-4, .user-information-section .col-xl-5, .user-information-section .col-xl-6, .user-information-section .col-xl-7, .user-information-section .col-xl-8, .user-information-section .col-xl-9, .user-information-section .col-xl-auto {
    padding-left: 15px;
    padding-right: 15px;
}

.user-information-section .form-user-information {
    padding: 0 10px;
}

.user-information-section select {
    word-wrap: normal;
    border: 1px solid #ced4da;
    border-radius: 5px;
    padding: 4px;
    min-width: 183px;
    margin-left: 5px;
}

.user-information-section .react-bootstrap-table {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    padding: 13px 13px 1px;
    border-radius: 5px;
}

.user-information-section .left-side-user-content {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    padding: 13px;
    border-radius: 5px;
    height: calc(100% - 36px);
}

.user-information-section .left-side-user-content .react-bootstrap-table {
    box-shadow: none;
    padding: 0;
    border-radius: 0px;
}

.user-information-section .left-side-user-content .react-bootstrap-table .table {
    margin-bottom: 0;
}

.user-information-section .user-form-section {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 5px;
    margin-right: 12px;
    height: calc(100% - 25px);
}


.user-information-section .title-userinfo-compo {
    font-weight: 700;
    text-align: left;
}

.user-information-section .table-tab-section {
    margin-top: 27px;
    padding-right: 0;
}

.user-information-section .btn-light {
    color: #7030a0;
    font-weight: bold;
    border: 1px solid #eee;
}

.user-information-section .table-2 .react-bootstrap-table th:last-child {
    width: 17%;
}

/* comunnication tab */
.user-information-section .communication-table-section .react-bootstrap-table .table th, .user-information-section .communication-table-section .react-bootstrap-table .table td {
    text-align: left;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
}

.user-information-section .communication-table-section .react-bootstrap-table .table th:first-child {
    width: 12%;
}

.user-information-section .communication-table-section .communication-btn {
    display: flex;
    justify-content: center;
    color: white;
    background: #23b14d;
    width: 120px;
    margin-left: auto;
    text-align: center;
    margin-bottom: 10px;
    margin-right: 10px;
}

.user-information-section .back-logout-btn {
    padding-top: 0 !important;
}

.user-information-section .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/* new */
.user-information-section .left-side-user-content th {
    vertical-align: baseline;
    font-family: 'Open Sans', sans-serif;
}

.user-information-section .left-side-user-content th, .user-information-section .left-side-user-content td {
    text-align: left;
    padding-left: 5px;
    word-break: break-all;
}


.user-information-section .col {
    padding-right: 0;
}

.user-information-section .react-tabs__tab-panel .react-bootstrap-table th, .user-information-section .react-tabs__tab-panel .react-bootstrap-table td {
    text-align: left;
    padding-left: 10px;
}

.user-information-section .react-tabs__tab-panel .react-bootstrap-table td:nth-child(5n+4), .user-information-section .react-tabs__tab-panel .react-bootstrap-table th:nth-child(5n+4) {
    text-align: center;
}

.user-information-section .react-tabs .react-bootstrap-table th, .user-information-section .react-tabs .react-bootstrap-table td {
    text-align: left;
    padding-left: 10px;
}

.table-data .react-bootstrap-table table th:last-child {
    width: 15%;
}

.contact-tab .react-bootstrap-table .table th:nth-child(6n+5) {
    width: 10%;
    text-align: center !important;
    padding-left: 0 !important
}

.contact-tab .react-bootstrap-table .table td:nth-child(6n+5) {
    width: 10%;
    text-align: center !important;
    padding-left: 0 !important
}


/* media query */
@media only screen and (min-width: 2420px) {
    .user-information-section .city-section {
        margin-left: 14px !important;
    }
}
@media only screen and (min-width: 2120px) {
    .user-information-section .city-section {
        margin-left: 10px;
    }
}
@media only screen and (max-width: 2120px) {
    .user-information-section .city-section {
        margin-left: 8px;
    }
}
@media only screen and (max-width: 1636px) {
    .user-information-section .communication-table-section .react-bootstrap-table .table th:first-child {
        width: 14%;
    }

    .user-information-section .user-form-section {
        margin-right: 0;
    }
}

@media only screen and (max-width: 1580px) {
    .user-information-section .left-side-user-content .user-list-table-left-side {
        width: 410px;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 1530px) {
    .user-information-section .left-side-user-content {
        margin-right: 10px;
        box-sizing: border-box;
    }

    .user-information-section .left-side-user-content .user-list-table-left-side {
        width: auto;
    }
}

@media only screen and (max-width: 1471px) {
    .user-information-section .communication-table-section .react-bootstrap-table .table th, .user-information-section .communication-table-section .react-bootstrap-table .table td {
        padding: 8px;
    }
}

@media only screen and (max-width: 1466px) {
    .user-information-section .city-section {
        min-width: auto;
        width: 113px;
        margin-left: 9px;
    }

    .user-information-section .table thead th {
        vertical-align: baseline;
        border-bottom: 2px solid #dee2e6;
    }

    /*.user-information-section .expires-section .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {padding-right: 0;}*/
    .user-information-section .month-form-content-input {
        margin-top: -23px;
    }

    .user-information-section .form-group .form-label {
        font-size: 13px;
        margin: 0;
    }

    .user-information-section select {
        min-width: 164px;
    }

    .user-information-section .react-tabs .react-tabs__tab {
        padding: 14px 6px;
        font-size: 16px;
    }

    .user-information-section .table-tab-section {
        padding-left: 10px;
    }

    .user-information-section .react-bootstrap-table {
        padding: 13px 9px 1px;
    }

    .react-tabs__tab-panel {
        overflow: auto;
        margin-bottom: 10px;
    }

    .table-data .react-bootstrap-table {
        overflow: auto;
    }

    .react-tabs__tab-panel .react-bootstrap-table table {
        margin-bottom: 0;
    }

    .react-tabs__tab-panel .react-bootstrap-table, .table-data .react-bootstrap-table table {
        width: 800px;
    }

    .react-tabs__tab-panel::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    .react-tabs__tab-panel::-webkit-scrollbar {
        background-color: #F5F5F5;
        height: 10px;
    }

    .react-tabs__tab-panel::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }

    .user-information-section .react-tabs .react-tabs__tab {
        width: 16.66%;
        font-size: 12px;
    }

    .user-information-section .communication-table-section .react-bootstrap-table .table th:first-child {
        width: 16%;
    }
}

@media only screen and (max-width: 1366px) {
    .user-information-section .react-tabs .react-tabs__tab {
        padding: 14px 0;
        font-size: 12px;
    }

    .user-information-section select {
        margin-left: 0;
    }

    .user-information-section .form-user-information {
        padding: 0 6px;
    }

    .user-information-section select {
        padding: 4px;
    }

    .user-information-section .btn-light {
        padding: 5px 14px !important;
    }

    .user-information-section .back-logout-btn {
        padding-top: 0 !important;
    }
}

@media only screen and (max-width: 1250px) {
    .user-information-section .user-form-section .col-lg-5, .col-lg-7, .user-information-section .user-form-section .table-tab-section {
        max-width: 100% !important;
        flex-basis: 100%;
    }

    .user-information-section .react-tabs .react-tabs__tab {
        padding: 19px 10px;
        font-size: 17px;
        width: 25%;
        text-align: center;
    }

    .user-information-section .city-section {
        min-width: auto;
        width: 225px;
        margin-left: 22px;
    }

    .user-information-section select {
        min-width: 261px;
    }

    .user-information-section .left-side-user-content .react-bootstrap-table .table {
        width: 100% !important;
    }

    .user-information-section .col-lg-5, .user-information-section .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .user-information-section .left-side-user-content {
        margin: 5px 10px 28px;
    }

    .user-information-section .user-form-section {
        margin-right: 0;
        margin: 5px 10px 28px;
    }

    .user-information-section .title-userinfo-compo {
        margin-left: 12px;
    }

}

@media only screen and (max-width: 1024px) {
    .user-information-section .user-info-row {
        flex-direction: column;
    }

    .user-information-section .city-section {
        min-width: auto;
        width: 87%;
        margin-left: 16px;
    }

    .user-information-section .user-form-section .payment-due-label {
        flex: 0 0 41.666667%;
        max-width: 41.666667% !important;
    }

    .user-information-section select {
        min-width: 220px;
    }

    .user-information-section .city-pincode-section {
        flex-wrap: nowrap;
    }

    .user-information-section .frequency-section input {
        width: 26%;
    }

    .user-information-section .month-form-content-input {
        margin-top: -25px;
    }

    .user-information-section .inner-user-info-section {
        margin: 0;
    }

    .user-information-section .divider-mobile {
        margin: 20px 0;
    }
}

@media only screen and (max-width: 991px) {
    .user-information-section .user-info-row {
        flex-direction: column;
    }

    .user-information-section .city-section {
        min-width: auto;
        width: 93%;
        margin-left: 4px;
    }

    .user-information-section .user-form-section .payment-due-label {
        flex: 100%;
        max-width: 100% !important;
    }

    .user-information-section select {
        min-width: auto;
        width: 94%;
    }

    .user-information-section .form-group .form-label {
        font-size: 14px;
    }

    .user-information-section .city-pincode-section {
        flex-wrap: nowrap;
    }

    .user-information-section .frequency-section input {
        width: 26%;
    }

    .user-information-section .month-form-content-input {
        margin-top: 0;
    }

    .user-information-section .inner-user-info-section {
        margin: 0;
    }

    .user-information-section .react-bootstrap-table {
        padding: 0 10px;
    }

    .user-information-section .title-userinfo-compo {
        margin-top: 10px;
    }

    .user-information-section #style-2 .react-bootstrap-table {
        margin: 0 10px;
    }

    .user-information-section .table-tab-section {
        padding-right: 10px;
    }

    .user-information-section .react-tabs {
        margin-bottom: 30px;
    }

    .user-information-section .react-bootstrap-table {
        padding: 13px 10px 1px;
    }

    .user-information-section .title-userinfo-compo {
        margin-left: 10px;
    }

    .user-information-section .user-form-section {
        margin: 0 10px;
    }

    .user-information-section .divider-mobile-data {
        background: #eeee;
        height: 12px;
        margin: 20px 0;
    }

    .user-information-section .table-tab-section {
        margin-top: 10px;
    }

    .user-information-section .left-side-user-content .user-list-table-left-side .react-bootstrap-table .table {
        width: 100%;
    }

    .user-information-section .left-side-user-content {
        margin-left: 10px;
    }
    .city-pincode-section .cityInput{
        padding-left: 0;
    }
    .ActionsBtn{
        padding: 0 16px !important;
    }
}

@media only screen and (max-width: 540px) {
    .user-information-section .react-bootstrap-table {
        overflow: hidden;
        width: 520px;
    }

    #style-2::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    #style-2::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }

    #style-2::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }

    .user-information-section .react-tabs .react-tabs__tab {
        font-size: 13px;
        padding: 14px 10px;
    }

    .user-information-section .table td, .table th {
        font-size: 13px;
    }

    .user-information-section .communication-table-section .communication-btn {
        font-size: 14px;
    }

    .user-form-section {
        margin-bottom: 40px;
    }

    .user-information-section .left-side-user-content .user-list-table-left-side .react-bootstrap-table .table {
        width: 750px;
    }
    .fields-container{
        flex-wrap: wrap;
    }
    .fields-container .search-field {
        width: 100% !important;
    }

}

@media only screen and (max-width: 414px) {
    .user-information-section .react-tabs .react-tabs__tab {
        font-size: 14px;
        padding: 10px 15px;
        width: 100%;
    }

    .user-information-section .react-bootstrap-table {
        overflow: auto;
        width: 356px;
    }

    .user-information-section .table-tab-section {
        margin-top: 19px;
    }

    .user-information-section .user-form-section {
        margin-bottom: 40px;
    }

    .user-information-section .react-bootstrap-table {
        padding: 13px 10px 12px;
    }

}

.inner-user-info-section .btn {
    width: 100%;
    margin-bottom: 10px;
}
.checkin-date-modal .date-label{
    margin-top: 2px;
}
.checkin-date-modal hr{
    margin-top: 3px!important;
    margin-bottom: 3px!important;
}


.user-info-row .countRowData{
    margin-top: 5px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.user-info-row .countRowData .search-field{
    width: 280px;
}
.user-info-row .countRowData .countController{
    width: 76px;
    display: flex;
}
