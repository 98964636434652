.select_type .MuiInputLabel-outlined {
    transform: translate(14px, 11px) scale(1);
}
.select_type .MuiOutlinedInput-input {
    padding: 0 14px !important;
}
.select_type .MuiSelect-selectMenu {
    min-height: 38px;
}
.report-data-container .MuiSelect-selectMenu{
    display: flex;
    align-items: center;
}
.report-data-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.report-data-container .date_picker {
    margin-left: 20px;
}
.notification-type-select {
    margin-left: 500px;
}