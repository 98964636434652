.communication-detail-section{margin:50px 30px;}
/* table */
.table-header-communication{padding: 14px; background: #3f47cc;color: white;text-align: center; font-weight: 600;}
.communication-detail-section .communication-table-section{padding-right: 10px;}
.communication-detail-section .communication-table-section .react-bootstrap-table .table th:first-child {width: 21%;}
.communication-detail-section .communication-table-section .react-bootstrap-table .table th:last-child {width: 21%; }
.communication-detail-section .communication-table-section .react-bootstrap-table .table th,.communication-detail-section .communication-table-section .react-bootstrap-table .table td{text-align: left; padding-left: 10px;padding-right: 10px;}
/* new Communication */
.communication-detail-section .row{margin-left: 0 !important; margin-right: 0 !important;}
.communication-detail-section .communication-right-section{padding-left: 30px;}
.communication-detail-section .conversation-info{margin-top: 10px;}
.communication-detail-section .inner-info-conversation{display: flex; align-items: center;}
.communication-detail-section .inner-info-conversation h6{width: 18%; font-weight: 600; margin-bottom: 1rem;font-family: 'Open Sans', sans-serif;font-size: 15px;}
.communication-right-section .add-new-btn-communication .btn-primary {color: #fff;background-color: #3f47cc;border-color: #3f47cc;border-radius: 0; font-weight: 600;}
.communication-right-section .conversation-chat-info {border: 2px solid darkgray;padding: 20px; min-height: 600px; margin-top: 5px;}
.communication-right-section .conversation-chat-info .inner-chat-content h6 {margin-bottom: 15px; font-size: 16px; font-weight: 600;}
.communication-right-section .conversation-chat-info .inner-chat-content p {margin-bottom: 0; font-size: 16px; font-weight: 500;}


@media only screen and (max-width: 1600px){
    .communication-detail-section .inner-info-conversation h6{width: 21%;}
    .communication-detail-section .communication-table-section .react-bootstrap-table .table th:first-child{width: 23%;}
    .communication-detail-section .communication-table-section .react-bootstrap-table .table td{font-size: 14px; padding-left: 5px;padding-right: 5px;}
    .communication-detail-section .communication-table-section .react-bootstrap-table .table th{padding-left: 5px;padding-right: 5px;}
    .communication-detail-section .communication-table-section{padding-right: 0;}
}
@media only screen and (max-width: 1550px){
    .communication-detail-section .inner-info-conversation h6{width: 23%;}
}
@media only screen and (max-width: 1400px){
    .communication-detail-section .inner-info-conversation h6{width: 25%;}
    .communication-detail-section{margin: 50px 20px;}
    .communication-detail-section .communication-table-section .react-bootstrap-table .table th:first-child{width: 24%;}
}
@media only screen and (max-width: 1350px){
    .communication-detail-section .communication-table-section .react-bootstrap-table .table th:first-child{width: 25%;}
}
@media only screen and (max-width: 1250px){
    .communication-detail-section .inner-info-conversation h6{width: 29%;}
}
@media only screen and (max-width: 1100px){
    .communication-detail-section .inner-info-conversation h6{width: 33%;}
}
@media only screen and (max-width: 1024px){
    .communication-inner-section{flex-wrap: wrap;}
    .communication-detail-section .communication-right-section{padding-left: 0; margin-top: 20px; }
    .communication-detail-section .inner-info-conversation h6{width: 18%; font-size: 14px;}
    .communication-detail-section .inner-info-conversation p{font-size: 14px;margin-bottom: 10px;}
    .communication-detail-section .divider-mobile{ background: #eeee;height: 12px;}
    .communication-right-section .conversation-chat-info .inner-chat-content h6,.communication-right-section .conversation-chat-info .inner-chat-content p {font-size: 14px; }
    
}
@media only screen and (max-width: 540px){
    .communication-right-section .conversation-chat-info{min-height: 400px;}
    .communication-detail-section .inner-info-conversation h6{width: 26%;}
    .communication-table-data .table{width: 768px;}
    .react-bootstrap-table{width: 500px;overflow: auto;}
}
@media only screen and (max-width: 540px){
    .communication-right-section .conversation-chat-info{min-height: 400px;}
    .communication-detail-section .inner-info-conversation h6{width: 26%;}
    .communication-table-data .table{width: 768px;}
    .react-bootstrap-table{width: 100%;overflow: auto;}
    .communication-detail-section{margin: 50px 10px;}
    .communication-right-section .conversation-chat-info{padding: 14px;}
    .communication-detail-section .inner-info-conversation h6{width: 39%;}
}