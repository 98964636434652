

.service-plan-section .table thead th {
    border-bottom: 2px solid darkgray;
}

.service-plan-section .react-bootstrap-table td, .service-plan-section .react-bootstrap-table th {
    border-color: darkgray;
}

.service-plan-section .table-bordered td {
    font-size: 14px;
    vertical-align: middle;
}

.service-plan-section .table-bordered td:first-child {
    text-align: left;
}

.service-plan-section .table-bordered th {
    font-size: 15px;
    padding: 10px 0px;
}

.service-plan-section .table-bordered th:first-child {
    width: 35%;
    text-align: left;
    padding-left: 10px;
}

.service-plan-section .table-bordered td:nth-child(5n+4), .service-plan-section .table-bordered td:nth-child(5n+5) {
    padding: 10px 5px;
}

.service-plan-section input, textarea.form-control {
    font-size: 1rem;
    border-color: darkgray;
    font-weight: 600;
}

.service-plan-section .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.service-plan-section .react-bootstrap-table th .order > .dropdown > .caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.dropup .caret, .service-plan-section .navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
}

.service-plan-section .react-bootstrap-table {
    cursor: pointer;
}

/* form */
.checkbox-all-check {
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    padding-left: 0 !important;
}

.checkbox-all-check .form-check-input {
    width: 20px;
    height: 17px;
}

.sort-order-lable {
    width: 23%;
}

.sort-order-input {
    width: 12%;
    text-align: center;
}

.checkbox-active-lable {
    width: 34%;
}

.service-plan-section .disable-btn-delete {
    cursor: not-allowed
}

.service-plan-section .Service-plan-addsection {
    padding: 0 30px;
}

.service-plan-section .Service-plan-addsection .col-form-label {
    text-align: left;
    font-weight: 700;
}

.service-plan-section .col-form-label {
    text-align: left;
    font-weight: 700;
}

.service-plan-section .Service-plan-addsection .form-check {
    text-align: left;
}

.service-plan-section .Service-plan-addsection .form-check-label {
    margin-bottom: 0;
    margin-left: 12px;
    font-weight: 700;
    font-size: 15px;
}

.service-plan-section .Service-plan-addsection .form-group {
    align-items: center;
    text-align: left;
}

.service-plan-section .form-label {
    font-weight: 700;
    font-size: 15px;
    margin-bottom: 0;
}

.service-plan-section .submite-button {
    background: #7030a0 !important;
    outline: none;
    border-color: #7030a0 !important;
    padding: 12px 62px;
    margin-top: 17px;
    width: 40%;
}

.add-button-section {
    text-align: end;
}

.add-button-section .btn-success {
    margin-right: 10px;
}

.service-plan-section .text-start {
    text-align: left;
    padding-left: 9px !important;
}

.service-plan-section .text-end {
    text-align: end;
    padding-right: 12px !important;
}

.right-side-table {
    padding-left: 30px !important;
}

.service-plan-section .btn-light, .faq-main-section .btn-light {
    border: 1px solid #eee;
    color: #7030a0;
    font-size: 14px;
    padding: .3rem .75rem !important;
}

.service-plan-section .add-button-section .btn {
    padding: .375rem 7px;
}

.service-plan-section .form-check-input {
    cursor: pointer;
}

.service-data-columm {
    position: relative;
}

.service-data-columm input {
    padding-left: 17px;
}

.service-data-columm span {
    position: absolute;
    top: 5px;
    left: 6px;
    font-size: 19px;
}

/* new Add */
.service-plan-section .table thead th:nth-child(6n+4) {
    width: 10%;
}

.Monthly-yerly-section .col {
    padding: 0;
}

.monthly-input-type input {
    width: 41%;
}

.Yearly-input-type input {
    width: 30%;
}
.left-side-user-content .react-bootstrap-table th.sortable {
    font-size: 14px !important;
    font-weight: 700 !important;
}
.left-side-user-content .react-bootstrap-table .table thead th:nth-child(2),
.left-side-user-content .react-bootstrap-table .table thead th:nth-child(3),
.left-side-user-content .react-bootstrap-table .table thead th:nth-child(4){
    width: 15%;
}

/* mediaQuery */
@media only screen and (max-width: 1366px) {
    .service-plan-section .table-bordered th:first-child {
        text-align: left;
        padding-left: 15px;
    }

    .back-logout-btn {
        padding: 0;
    }

    .content-table-form {
        padding: 12px 0 0 !important;
    }

    .service-plan-section .table-bordered td:nth-child(5n+4), .service-plan-section .table-bordered td:nth-child(5n+5) {
        padding: 18px 5px;
    }

    .react-bootstrap-table th.sortable {
        vertical-align: baseline;
    }

    .service-plan-section .row-input-changer .col-md-4 {
        flex: 0 0 28.333333%;
        max-width: 28.333333%;
    }

    .Service-plan-addsection .row {
        margin-left: 0;
        margin-right: 0;
    }

    .checkbox-active-lable {
        width: 35%;
    }

    .sort-order-lable {
        width: 34%;
    }

    .sort-order-input {
        width: 19%;

    }

    .sort-sec {
        padding-right: 4px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .Service-plan-addsection {
        padding: 20px;
    }

    .right-side-table {
        padding-left: 20px !important;
    }

    .table thead th {
        vertical-align: baseline;
    }
}

@media only screen and (max-width: 1024px) {
    .right-side-table {
        padding-left: 0 !important;
        padding: 0 10px !important;
    }

    .Service-plan-addsection {
        padding: 8px;
    }

    .service-plan-section .table-bordered th:first-child {
        width: 22%;
    }

    .mobile-direction {
        flex-direction: column-reverse;
    }

    .mobile-direction .col-lg-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .right-side-table {
        margin-top: 30px;
    }

    .divider-mobile {
        background: #eeee;
        height: 12px;
    }

    .mobile-button {
        padding-bottom: 19px;
    }
}

@media only screen and (max-width: 540px) {
    .Service-plan-addsection {
        padding: 4px;
        margin-top: 17px;
    }

    .service-plan-section .table-bordered th {
        font-size: 14px;
        padding: 24px 10px;
    }

    .add-button-section {
        margin-bottom: 20px;
    }

    .submite-button {
        padding: 16px 114px;
        margin-top: 15px;
    }

    .btn-light {
        font-size: 15px;
    }

    .mobile-direction {
        padding-top: 0 !important;
    }

    .service-plan-section .row-input-changer .col-md-4 {
        position: relative;
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
    }
}

@media only screen and (max-width: 414px) {
    .Service-plan-addsection .form-check-label {
        margin-left: 0;
    }

    .Service-plan-addsection .form-check {
        margin-left: 10px;
    }

    .Service-plan-addsection {
        padding: 0px;
    }

    .submite-button {
        padding: 12px 114px;
        margin-bottom: 19px;
    }

    .right-side-table {
        padding: 0 6px !important;
    }

    .text-start {
        padding-left: 8px !important;
    }

    .text-end {
        padding-right: 15px !important;
        padding-top: 10px !important;
    }

    .add-button-section {
        margin: 20px 0;
    }

    .Service-plan-addsection .form-group {
        width: 100%;
    }

    .Service-plan-addsection .form-group {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .Service-plan-addsection .form-group .col-sm-5, .col-12, .col-sm-2 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    #style-2::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 10px;
        background-color: #F5F5F5;
    }

    #style-2::-webkit-scrollbar {
        width: 5px;
        background-color: #F5F5F5;
    }

    #style-2::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
        background-color: #555;
    }

    .table {
        width: 732px;
        margin-bottom: 0 !important;
        color: #212529;
    }

    .right-side-table {
        width: 1000px;
        overflow: auto;
        margin-bottom: 30px;
    }
}
