.page-creation-template select  {font-size: 15px; border: 2px solid darkgray;  width: 50%; margin-left: 35px;   }
.page-creation-template .form-group .form-label {width: auto ; margin-bottom: 5px !important;}
.page-creation-template .Checked-box{margin-left: 20px;}
.page-creation-template .table-bordered td{vertical-align: middle; text-align: center;}
.page-creation-template .react-bootstrap-table table th:first-child{width: 40%;}
.page-creation-template .table-bordered td:first-child{text-align: left;}
.page-creation-template input{font-size: 15px;}
.page-creation-template .react-bootstrap-table table th:nth-child(4n+3), .page-creation-template .react-bootstrap-table table td:nth-child(4n+3){width: 15%;}
/* media Query */
@media only screen and (max-width: 1366px) {
    .page-creation-template .react-bootstrap-table table th:first-child{width: 29%;}
    .faq-main-section .right-side-table{padding-left: 14px !important;}
    .faq-main-section .Service-plan-addsection{padding: 0 10px 0 14px;}
    .faq-main-section .react-bootstrap-table table th:nth-child(4n+2), .faq-main-section .react-bootstrap-table table td:nth-child(4n+2){width: 18%;}
}
@media only screen and (max-width: 1024px) {
    .page-creation-template .react-bootstrap-table table th:first-child{width: 45%;}
}

@media only screen and (max-width: 540px) {
    .faq-main-section .right-side-table{width: 1000px; overflow: auto;}
    .faq-main-section .react-bootstrap-table table{width: 900px;}
}