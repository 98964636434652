.user_daily_check_table .react-bootstrap-table {
    /*border: 2px solid black;*/
    height: 680px;
    overflow-y: scroll;
}
.user_daily_check_table .table thead th {
    /*border-color: black !important;*/
    font-size: 15px !important;
}

.user_daily_check_table .table td {
    font-size: 15px !important;
    padding: 5px;
    word-break: break-all;
}

.row_details .modal-header {
    border-bottom: none;
    background-color: #7030a0;
    color: white;
    justify-content: center;
}
.row_details .modal-header span{
    color: white;
}

.row_details .modal-dialog {
    max-width: 98%;
    margin: 1.75rem auto;
}
.row_details .modal-body{
    padding-top: 0;
}
.row_details .modal-body .checkin-date{
    text-align: center;
    margin: 0;
    font-weight: bold;
    font-size: 17px;
}
.row_details .modal-body .closeIcon{
    padding: 0;
    justify-content: flex-end;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    background-color: transparent;
    color: black;
    border: none;
    box-shadow: none !important;
}
.row_details .modal-body .closeIcon:hover{
    background-color: transparent;
    box-shadow: none;
    color: black;
    border: none;
}

.row_details .modal-dialog .form_label {
    width: 168px;
}
.table_1 tr td:nth-child(2) .btn{
    margin-left: 5px;
}
.table_1 tr td{
    vertical-align: middle;
}
.table_1 td,
.table_1 thead th {
    border-color: transparent !important;
}
.table_1 tr .approval-btn{
    max-width: 120px;
    border-radius: 10px;
}
.table_1 tr .performed-by span{
    border: 1px solid black;
    padding: 5px;
    width: 100%;
    display: block;
    min-height: 30px;
}
.action_btn .btn:focus{
    box-shadow: none !important;
}
.action_btn .btn:hover{
    border-color: #7030a0;
}
.edit_data_form .row-fields{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}
.edit_data_form .row-fields .form-label{
    width: 205px;
}
.checkinTable tr {
    cursor: pointer;
}
.checkinTable tbody tr.active{
    background-color: #e6e6e7;
}
.checkinTable td:nth-child(n+1):nth-child(-n+5){
    width: 8%;
    word-break: break-all;
}
.checkinTable td:nth-child(5){
    width: 13% !important;
}
.checkinTable .checkbox,
.carry_over{
    width: 1%;
}
.date_reminder_content .react-datepicker-wrapper{
    width: 100%;
}
.date_reminder_content .date_picker .form-control{
    cursor: pointer;
}
.date-picker-container .date-picker-icon input{
    cursor: pointer;
}
.date_reminder_content .date_picker{
    position: relative;
    width: 35%;
    cursor: pointer;
    margin-right: 20px;
}
.date_reminder_content .date_picker img{
    width: 25px;
    position: absolute;
    right: 5%;
    top: 14%;
    pointer-events: none;
}

.record_count_label h5,
.record_count_label .form-check-label{
    color: #212529;
    font-size: 20px;
    font-weight: 500;
}
.record_count_label .form-check-input{
    margin-top: 10px;
}
.record_count_label .form-group{
    width: 260px;
}
.user_daily_check_table .table{
    table-layout: auto;
}
/*.user_daily_check_table .table thead th:nth-child(1),*/
/*.user_daily_check_table .table thead th:nth-child(2){*/
/*    width: 8%;*/
/*}*/
/*.user_daily_check_table .table thead th:nth-child(3){*/
/*    width: 7%*/
/*}*/
/*.user_daily_check_table .table thead th:nth-child(4),*/
/*.user_daily_check_table .table thead th:nth-child(5){*/
/*    width: 8%;*/
/*}*/
/*.user_daily_check_table .table thead th:nth-child(7),*/
/*.user_daily_check_table .table thead th:nth-child(8),*/
/*.user_daily_check_table .table thead th:nth-child(9){*/
/*    width: 7.5%;*/
/*}*/
/*.user_daily_check_table .table thead th:nth-child(8){*/
/*    white-space: break-spaces;*/
/*}*/
@media (max-width: 576px) {
    .edit_data_form .form-group{
        flex-wrap: wrap;
    }
    .table_1 {
        width: 500px;
    }
}
.assign-confirmation-modal .modal-header span {
    color: white;
}
.assign-confirmation-modal .btn-yes {
    background-color: #7030a0;
    border: none;
    color: #fff;
}
.add-notes-btn {
    margin-top: -220px;
    z-index: 1;
    position: relative;
}
.add-notes-modal .modal-content {
    width: 800px;
}
.textarea-field .form_label{
    width: 170px;
}

@media only screen and (max-width: 1368px) {
    .record_count_label{
        margin-top: 5px;
    }
}
