.notification-template-inner-content {
    margin-bottom: 20px;
}

.notification-template-inner-content .table  th:first-child {
    width: 15% !important;
    text-align: center !important;
}
.notification-template-inner-content .table  td:first-child {
    text-align: center !important;
}
.notification-template-inner-content .table  th:nth-child(2) {
    width: 42% !important;
}

.notification-template-inner-content .table th:nth-child(4n+2),
.notification-template-inner-content .table  td:nth-child(4n+2){
    padding-left: 15px;
    text-align: left !important;
}
