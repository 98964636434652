.terms-of-use-add-delet-btn{display: flex;align-items: center;justify-content: space-between;margin-bottom: 10px;}
.terms-of-use-add-delet-btn  .form-label{margin-bottom: 0 !important;}
.terms-of-use-table .react-bootstrap-table .table th:first-child{width: auto;}
.terms-of-use-table .react-bootstrap-table .table td p{margin-bottom: 0;}
.terms-of-use-table .react-bootstrap-table::-webkit-scrollbar-track , .faq-main-section .react-bootstrap-table::-webkit-scrollbar-track{-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);border-radius: 20px;background-color: #F5F5F5;}
.terms-of-use-table .react-bootstrap-table::-webkit-scrollbar , .faq-main-section .react-bootstrap-table::-webkit-scrollbar{width: 5px;background-color: #F5F5F5;}
.terms-of-use-table .react-bootstrap-table::-webkit-scrollbar-thumb , .faq-main-section .react-bootstrap-table::-webkit-scrollbar-thumb{border-radius: 10px;-webkit-box-shadow: inset 0 0 6px #eee;background-color: #555;}
.terms-of-use-main-section .tox-toolbar__group:nth-child(7n+3){width:7% !important;}
.terms-of-use-main-section .tox-toolbar__group:nth-child(7n+3) .tox-tbtn--bespoke{width: 100%!important;}
.react-bootstrap-table {cursor: pointer;}
.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}
/* preview */
.PreviewTerms-content h5{font-weight: 600;margin-bottom: 5px;}
.preview{    padding: 3px 10px;
    margin-bottom: 7px;}


@media only screen and (max-width: 1250px) {
    .faq-main-section .right-side-table{max-width: 100%;flex-basis: 100%;}
}
