/*comunication model*/
.communication-model{
    min-height: 400px;
}
.communication-model .new-conversation{
    display: flex;
    justify-content: end;
}
.communication-model .new-conversation .btn {
    margin: 5px 0;
}
.communication-model .react-bootstrap-table td:nth-child(3) span{
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/*new comunication model*/
.new-communication-model{
    min-height: 400px;
}
.new-communication-model .new-conversation{
    display: flex;
    justify-content: end;
}
.new-communication-model .communcation-notes{
    margin-top: 5px;
}
