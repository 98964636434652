.checkinTable{
    height: 100%;
    margin-bottom: 0;
}
.table-hover tbody tr:hover {
    cursor: pointer;
}
.checkinTable .custom_loader:hover{
    background: transparent;
}
.checkinTable .circular_progress{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}