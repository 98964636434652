.activated-pause-content{padding: 80px 81px ;}
.activated-push-account label,.activated-pause-section label{ font-size: 18px; font-weight: 600;}
.activated-push-account .form-check{padding-left: 0;}
input#flexRadioDefault1,input#flexRadioDefault2{width: 20px;height: 20px;}
.activated-input-div label{width: 20%;margin-bottom: 0;}
.activated-input-div input{width: 10%;}
input[type=radio]{cursor: pointer;}
.activated-pause-section .submite-button{background:  #7030a0 !important;outline: none;border-color: #7030a0 !important;padding: 12px 62px;margin-top: 17px; width: 30%; margin:30px auto ;    display: flex;    justify-content: center;}
@media only screen and (max-width: 1024px){
    .activated-pause-content{padding: 60px 39px ;}
    .activated-pause-section .col-lg-6{ flex: 0 0 50%;max-width: 50%;}
}

@media only screen and (max-width: 768px){
    .activated-input-div label {width: 29%;}
    .activated-input-div input{width: 14%;}
}

@media only screen and (max-width: 540px){
    .activated-push-account label,.activated-pause-section label{font-size: 16px; font-weight: 600;}
    .activated-input-div label {
        width: 39%;
    }
    .activated-input-div input {
        width:20%;
    }
}

@media only screen and (max-width: 414px){
    .activated-input-div label { width: 100%;}
    .activated-input-div,.activated-push-account{flex-wrap: wrap;}
    .activated-input-div input {width:100%;}
    .activated-pause-content {padding: 27px 15px;}
    .activated-push-account label,.activated-pause-section label {font-size: 15px;}
    .radio-btn{margin-bottom: 10px;}

}