.custom-info-table td{
    word-break: break-all;
    padding: 5px;
    /*text-align: center;*/
}
.custom-info-model-body .react-bootstrap-table th:nth-child(4){
    width: 100px !important;
}

/*contact table*/
.contact-info-model-body .react-bootstrap-table th:nth-child(4){
    width: 80px;
}
.contact-info-model-body .react-bootstrap-table th:nth-child(5){
    width: 100px;
}
.contact-info-model-body .react-bootstrap-table td{
    width: 100px !important;
    text-align: left;
}
.contact-info-model-body .react-bootstrap-table td:nth-child(4),
.contact-info-model-body .react-bootstrap-table td:nth-child(5){
    text-align: center;
}

.contact-info-model-body .react-bootstrap-table th:nth-child(4),
.contact-info-model-body .react-bootstrap-table th:nth-child(5){
    text-align: center;
}
.contact-info-model-body .react-bootstrap-table th:last-child{
    width: 330px;
}
.service-info-table .modal-header .modal-title{
    font-size: 22px;
}
.service-info-table .modal-xl{
    max-width: 1400px;
}