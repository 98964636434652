.LogoutBtn Button{
    background-color:#7030a0 !important ;
    color: white !important;
    height:39px;
    width: 97px;
    font-size: 16px !important;
}
.LogoutBtn Button:hover{
    background-color:#7030a0 !important ;
}

.LogoutBtn .LogoutIcon{
    color: white;
    position: absolute;
    top: 20px;
    padding-left:2px !important;
}
.LogoutBtn .btn-primary:hover {
    color: #fff;
    background-color:#7030a0 !important ;
    border-color: #7030a0 !important;
}