.subscribe-pan-section .subscribe-inner-section .react-tabs__tab-list{	border: 1px solid #eee !important;box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);    border: none; width: 25%; margin: 0 auto; margin-bottom: 20px;}
.subscribe-pan-section .subscribe-inner-section .react-tabs__tab--selected{border: none; box-shadow: none;      font-weight: 600;  background: #7030a0;color: white;}
.subscribe-pan-section .subscribe-inner-section .react-tabs__tab{width: 50%; font-weight: 600;  text-align: center;   margin-top: -2px;   border-radius: 5px;}
.pricing-title-header{align-items: center;}
.pricing-title-header img{border-radius: 20px;}
.pricing-title-header .price_amount_right {padding-left: 20px;}
.pricing-title-header {border-bottom: 2px solid#e5e7eb; padding-bottom: 20px;}
.pricing-content ul{list-style-type: none; padding: 0; padding-top: 20px;}
.pricing-content li{font-size: 1.125rem;line-height: 1.75rem; color: rgba(107,114,128,1);font-weight: 500;}
.pricing-content li span{font-weight: 600;}
.subscribe-pan-section .row {justify-content: center; margin-left: 0; margin-right: 0;}
.subscribe-inner-section{margin-top: 80px;}
/* common pricing table*/
.pricing-table {background: #fff;box-shadow: 0px 1px 10px -6px rgba(0, 0, 0, .15);padding: 27px;border-radius: 1rem;transition: .3s;border: 1px solid #eee;}
.pricing-table:hover {box-shadow: 0px 1px 10px -4px rgba(0, 0, 0, .15);}
.pricing-table .pricing-label {border-radius: 2px;padding: .25rem .5rem;margin-bottom: 1rem;display: inline-block;font-size: 12px;font-weight: 500;}
.pricing-table h2 {color: #000;font-size: 1.6rem;line-height: 2rem;font-weight: 700;margin-bottom: 0;}
.pricing-table .symbol {color: rgba(107,114,128,1);font-size: 21px;font-weight: 600;}
.pricing-table .amount {font-weight: 700;font-size: 1.875rem;line-height: 2.25rem;letter-spacing: -1px;color: dimgrey;}
.pricing-table .price-button {display: block;color: #fff;margin-top: 2rem;padding: .75rem;border-radius: 2px;text-align: center;font-weight: 500;transition: .3s;border-radius: .7rem;background:#7030a0;font-size: 18px;}
.pricing-table .price-button:hover {text-decoration: none;background:#7030a0;}
.paymnet-plan-data h6{width: 25%;}
.plan-details-section h6{font-weight: 600;}
.plan-details-section p{margin-bottom: 0;}
.paymnet-title-detail{ padding-bottom: 10px;}

.border-dotted-bottom{
    border-bottom: 1px dotted;
}
/* media query */
@media only screen and (max-width: 1480px) {
    .pricing-table-sec{margin-left: 20px;}
}
@media only screen and (max-width: 1250px) {
    .pricing-table{padding:20px 20px;}

}
@media only screen and (max-width: 768px){
    .subscribe-pan-section .subscribe-inner-section .react-tabs__tab-list{width: 40%;}
}
@media only screen and (max-width: 540px) {
    .pricing-table-sec{margin-left: 0;}
    .pricing-table{margin-bottom: 20px;}
    .subscribe-pan-section .subscribe-inner-section .react-tabs__tab-list{width: 52%;}
}
@media only screen and (max-width: 540px) {
    .subscribe-pan-section .subscribe-inner-section .react-tabs__tab-list{width: 80%;}
}