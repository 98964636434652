.maintenance-section .maintenance-content-flex {
    display: flex;
    flex-wrap: wrap;
    /*justify-content: center;*/
}

.maintenance-section .maintenance-content-card {
    margin-top: 40px;
}


.maintenance-section .maintenance-content-flex .card {
    width: calc(34% - 30px);
    margin-bottom: 25px !important;
    background: #a2489f;
    border-radius: 0;
    padding: 12px;
    font-family: 'Open Sans', sans-serif;
}

.maintenance-section .maintenance-content-flex .card .card-title {
    margin-bottom: 0 !important;
    padding-left: 20px;
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

.maintenance-content-flex .card .card-body {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    background: white;
    border-radius: 8px;
}

.maintenance-content-flex .card a {
    text-decoration: none;
    color: black;
}

.maintenance-content-flex .card a:hover {
    color: #a2489f;
}

.maintenance-content-flex .card .card-body img {
    width: 30%;
    height: 152px;
}

.maintenance-section .maintenance-content-flex .card:nth-child(3n+2) {
    margin: 0px 30px;
}

.maintenance-section .logout-option {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.maintenance-section .logout-option a {
    color: #7030a0;
    font-weight: 700;
    margin-top: 10px;
    margin-right: 10px;
    border: 1px solid #eee;
}
.custom-header, .custom-link {
    background-color: #7030a0 !important;
    border-color: #7030a0 !important;
}
.custom-link:hover {
    background: #7030a0;
}
.MuiSvgIcon-root {
    width: 20px!important;
    height: 20px!important;
}
/* media Query */
@media only screen and (max-width: 1024px) {
    .container, .container-md, .container-sm {
        max-width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .maintenance-section .maintenance-content-flex .card {
        width: calc(52% - 30px);
    }

    .maintenance-section .maintenance-content-flex .card:nth-child(3n+2) {
        margin: 0;
    }

    .maintenance-section .maintenance-content-flex .card:nth-child(2n+1) {
        margin-right: 23px;
    }

    .maintenance-section .maintenance-content-card {
        margin-top: 67px;
    }
}

@media only screen and (max-width: 540px) {
    .maintenance-section .maintenance-content-flex .card .card-title {
        margin-bottom: 0 !important;
        padding-left: 13px;
        font-size: 15px;
    }

    .maintenance-section .maintenance-content-card {
        margin-top: 37px;
    }

    .maintenance-section .maintenance-content-flex .card {
        padding: 10px;
    }

    .maintenance-content-flex .card .card-body {
        padding: 29px 10px;
    }
}

@media only screen and (max-width: 414px) {
    .maintenance-section .maintenance-content-card {
        margin-top: 32px;
    }

    .maintenance-section .maintenance-content-flex .card:nth-child(2n+1) {
        margin-right: 0px;
    }

    .maintenance-section .maintenance-content-flex .card {
        width: 100%;
        padding: 15px;
        margin-bottom: 19px !important;
    }

    .maintenance-section .maintenance-content-flex .card .card-title {
        font-weight: 600;
    }

    .maintenance-section .maintenance-content-flex .card .card-title {
        font-size: 16px;
    }
}
