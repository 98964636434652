.faq-main-section .react-bootstrap-table table{text-align: left;}

.faq-main-section .react-bootstrap-table th,.faq-main-section .react-bootstrap-table td{border-color: darkgray;font-family: 'Open Sans', sans-serif !important;}
.faq-main-section .react-bootstrap-table table th:first-child{width: 45%;}
.faq-main-section .react-bootstrap-table table th:nth-child(4n+2), .faq-main-section .react-bootstrap-table table td:nth-child(4n+2){text-align: center; }
.faq-main-section .react-bootstrap-table table td:nth-child(4n+2){vertical-align: middle;}
.faq-main-section .react-bootstrap-table table th:nth-child(4n+4), .faq-main-section .react-bootstrap-table table td:nth-child(4n+4){text-align: center;vertical-align: middle;}
.faq-main-section .react-bootstrap-table table th:nth-child(4n+3), .faq-main-section .react-bootstrap-table table td:nth-child(4n+3){text-align: center;vertical-align: middle; }
.faq-main-section .preview-btn {margin-top: -15px;color: white; font-weight: 600; margin-right: 65px; font-size: 14px;    padding: .2rem .75rem; }
.faq-main-section .preview-btn:hover{color: white;}
.faq-main-section .header-section h1{font-size: 27px;}
.faq-main-section .form-faq-section label {font-size: 14px;font-family: 'Open Sans', sans-serif !important;}
.faq-main-section .form-faq-section .form-control:disabled, .form-control[readonly]{background-color: white;}
.faq-main-section .form-faq-section input{border: 2px solid darkgray !important;}
.faq-main-section input[type=checkbox]{width: 22px;height: 15px;}
.faq-main-section  .table th:first-child{text-align: left !important; padding-left: 5px;}
/* form, */
.faq-main-section .from-group-section{display: flex; align-items: center;}
.faq-main-section .form-group .form-label{width: 12%; font-weight: 700; font-size: 14px;}
.faq-main-section input{width: 100%;}
.faq-main-section .active-status  .form-label{width: 10%; margin: 0;}
.faq-main-section .add-button-section .btn{        font-size: 15px;    padding: .2rem .75rem; }
.faq-main-section input{font-family: 'Open Sans', sans-serif !important;}
.faq-main-section .submite-button{width: 30%;font-size: 16px;    padding: .3rem .75rem;}
.faq-main-section .table-bordered td{padding: 10px 5px; font-size: 14px;}
.faq-main-section .react-bootstrap-table table td:nth-child(4n+4),.faq-main-section .react-bootstrap-table table td:nth-child(4n+3){padding: 10px 0px;}
.faq-main-section .react-bootstrap-table table th{font-size: 14px;}
.faq-main-section .row{margin-left: 0 !important; margin-right: 0 !important;}
.faq-main-section .submite-button{background:  #7030a0 !important;outline: none;border-color: #7030a0 !important;padding: 10px 62px;margin-top: 17px; width: 30%;}
/* same */
 .Service-plan-addsection{padding:0 30px;}
 .Service-plan-addsection .col-form-label{text-align: left; font-weight: 700;}
 .col-form-label{text-align: left; font-weight: 700;}
 .Service-plan-addsection .form-check{text-align: left;}
 .Service-plan-addsection .form-check-label {margin-bottom: 0;margin-left: 12px;font-weight: 700;font-size: 15px;}
 .Service-plan-addsection .form-group{align-items: center;text-align: left;}
 .faq-main-section td p{margin-bottom: 0;}
.faq-main-section .react-bootstrap-table { max-height: 777px;overflow: auto;}
.faq-main-section .react-bootstrap-table .table{margin-bottom: 0;}
.tox-statusbar{display: none !important;}
/* new css */
.tox-tinymce{border-radius: 5px!important;}
.editor-data-new .tox-tinymce{    height: 150px !important;}
.preview-editor-sec-enw .tox-tinymce{    height: 320px !important;}
.preview-editor-sec-enw-faq .tox-tinymce{    height: 320px !important;}
.faq-main-section .tox-toolbar__group:nth-child(7n+3){width:8% !important;}
.faq-main-section .tox-toolbar__group:nth-child(7n+3) .tox-tbtn--bespoke{width: 100%!important;}
.faq-main-section .tox .tox-menubar{display: none;}
.faq-main-section .tox .tox-toolbar__group{padding: 0 2px !important;}
.faq-inner-content{margin-bottom:10px;}


.common-font-section{
	font-family: 'Open Sans', sans-serif !important;
}
.faq-main-section .react-bootstrap-table th,
.user-information-section .react-bootstrap-table th,
.user-information-section .form-group .form-label,.service-plan-section .react-bootstrap-table th{font-weight: 700;}

/* media query */
@media only screen and (max-width: 1631px) {
  .faq-main-section .Service-plan-addsection{padding: 0 15px 0 30px;}
  .faq-main-section .right-side-table{padding-left: 17px !important;}
}

@media only screen and (max-width: 1500px) {
	.faq-main-section .table-bordered td {
		padding: 10px 5px;
		font-size: 13px;
	}
}

@media only screen and (max-width: 1453px) {
	.faq-main-section .active-status .form-label {
		width: 12%;
	}
	.faq-main-section .tox-toolbar__group:nth-child(7n+3){width: 10% !important;}
}

@media only screen and (max-width: 1440px) {

	.faq-main-section .Service-plan-addsection {
		padding: 0 15px 0 20px;
	}
}

@media only screen and (max-width: 1410px) {

	.faq-main-section .Service-plan-addsection {
		padding: 0 15px 0 17px;
	}
	.faq-main-section .right-side-table {
		padding-left: 21px !important;
	}
}

@media only screen and (max-width: 1366px) {

	.faq-main-section .preview-btn {
		font-size: 14px;
	}
	.faq-main-section .add-button-section .btn {
		font-size: 14px;
	}
	.faq-main-section .Service-plan-addsection {
		padding: 0 15px 0 14px;
	}
	.faq-main-section .active-status .form-label {
		width: 12%;
	}
	.faq-main-section .right-side-table {
		padding-left: 23px !important;
	}
}

@media only screen and (max-width: 1300px) {
	.faq-main-section .table thead th {
		padding: 10px 5px;
	}
	.faq-main-section .table-bordered td {
		font-size: 12px;
	}
	.FAQ-inner-Content-sec .col-lg-6{    flex: 0 0 100%;
		max-width: 100%;}
		.faq-main-section .tox-toolbar__group:nth-child(7n+3){width: 10% !important;}
}

@media only screen and (max-width: 1024px) {
	.faq-main-section .faq-main-section .form-label {
		width: 11%;
	}
	.faq-main-section .active-status .form-label {
		width: 9.9%;
	}
	.faq-main-section button.submite-button.w-50.btn.btn-primary {
		margin-bottom: 8px;
	}
  .faq-main-section .right-side-table{
    padding: 0 20px !important;
  }

}

@media only screen and (max-width: 991px) {
	.faq-main-section .Service-plan-addsection {
		padding: 0 4px 0 3px;
  }
  .faq-main-section .right-side-table{
    padding: 0px 12px !important;
  }
  .faq-main-section .tox-toolbar__group:nth-child(7n+3) {
    width: 11% !important;
}
}
/*@media only screen and (max-width: 872px) {*/
/*	.header-section .right-side-text {*/
/*		top: 43px;*/
/*	}*/
/*}*/

@media only screen and (max-width: 768px) {
	.header-section .right-side-text {
		top: 65px;
		left: 0;
		justify-content: center;
	}
	.faq-main-section .header-section h1 {
		font-size: 23px;
	}
	.faq-main-section .submite-button {
		margin: 20px 0;
	}
	.faq-main-section .back-logout-btn {
		padding: 10px 0 !important;
	}
	.faq-main-section .form-label {
		width: 16%;
	}
	.faq-main-section .active-status .form-label {
		width: 13.7%;
	}
	.faq-main-section button.submite-button.w-50.btn.btn-primary {
		margin-bottom: 25px;
	}
	.faq-main-section .form-label {
		font-size: 15px;
	}
	.faq-main-section .from-group-section {
		flex-wrap: wrap;
		width: 100%;
		margin-right: 0 !important;
	}
}

@media only screen and (max-width: 540px) {
	.faq-main-section .preview-btn {
		margin-top: -11px;
	}
	.faq-main-section .preview-btn-lable {
		margin-bottom: 10px;
	}
	.faq-main-section .header-section h1 {
		font-size: 20px;
	}
	.faq-main-section .Service-plan-addsection .row {
		margin-right: 0;
		margin-left: 0;
	}
	.faq-main-section .Service-plan-addsection {
		padding: 0;
	}
	.faq-main-section .preview-btn {
		margin-top: -2px;
	}
  .faq-main-section .right-side-table{
    padding: 0 .5rem !important;
  }
}

.main-container .rdw-colorpicker-modal {
	width: 250px;
	left: -37px;
}
.ColorPicker-Popup {
	width: 250px;
	height:250px;
}